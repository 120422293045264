import React from 'react';

import arrow from '@images/icons/right-chevron.svg';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Review } from './Review';

import styles from './ReviewsList.module.scss';

export function ReviewsList() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.testimonialsTitleContainer}>
        <h2 className={styles.testimonialsTitle}>Фотокниги наших клиентов</h2>
        <Link to="/blog/idei-i-primery-fotoknig/" className={styles.testimonialsMore}>
          Идеи для ваших фотокниг
          <img src={arrow} alt="arrow" />
        </Link>
      </div>
      <div className={styles.content}>
        <div className={styles.content__reviewsBlock}>
          <Review
            name="@nataplushcheva"
            bookUrl="/products/photobooks/big-photobook/"
            photo={
              <StaticImage
                src="../../../images/image.jpg"
                layout="constrained"
                width={408}
                alt="Отзыв @nataplushcheva"
              />
            }
          />

          <Review
            name="@nellyamartirosove"
            bookUrl="/products/photobooks/big-hard-book-darkred/"
            photo={
              <StaticImage
                src="../../../images/image2.jpg"
                layout="constrained"
                width={408}
                alt="Отзыв @nellyamartirosove"
              />
            }
          />

          <Review
            name="@natasja_resh.foto"
            bookUrl="/products/photobooks/hardbook-photo-vertical/"
            photo={
              <StaticImage
                src="../../../images/image3.jpg"
                layout="constrained"
                width={408}
                alt="Отзыв @natasja_resh.foto"
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
