import React from 'react';
import Layout from '@components/Layouts/Layout';
import { ProductsRoot } from '@components/Blocks/ProductsRoot';
import { Services } from '@components/Blocks/Services';
import { RecommendationsGroupped } from '@components/Blocks/RecommendationsGroupped';
import { SchemaOrgOrganization } from '@components/Layouts/SchemaOrg/SchemaOrgOrganization';
import { DesktopBanner, MobileBanner } from '@components/Blocks/Banners';
import SEO from '@components/Layouts/SEO';
import { StaticImage } from 'gatsby-plugin-image';

import { useBanners } from '@hooks/useBanners';

import ProductsTop from '@components/Blocks/ProductsTop';
import { AboutProject } from '@components/Blocks/AboutProject';

import { PromoBlock } from '@components/Blocks/PromoBlock';
import { ReviewsList } from '@components/Blocks/ReviewsList';

export function Head() {
  return (
    <>
      <SEO title=" Фотокнига на заказ: изготовление и печать фотокниг в Москве с доставкой по России" />
      <SchemaOrgOrganization />
    </>
  );
}

function IndexPage() {
  const desktopItems = useBanners();
  const mobileItems = useBanners(true);

  return (
    <Layout>
      <DesktopBanner items={desktopItems} />
      <MobileBanner items={mobileItems} />
      <ProductsRoot />
      <ProductsTop />
      <Services header="Как за 4 простых шага сделать фотокнигу" />

      <PromoBlock
        image={
          <StaticImage
            src="../images/ios-app.jpg"
            layout="constrained"
            width={647}
            alt="Фотокнига за 10 минут в iOS-приложении"
          />
        }
        title="Фотокнига за 10 минут в iOS-приложении"
        text="Добавьте фотографии из галереи телефона и соберите фотокнигу с помощью нейросети даже без интернета"
        link="https://app.appsflyer.com/id1005018803?pid=landing_page_periodica&c=home_page"
        buttonText="Установить приложение"
      />

      <PromoBlock
        image={
          <StaticImage
            src="../images/szv-photo.jpg"
            layout="constrained"
            width={647}
            alt="Доверьте оформление семейных воспоминаний нашим дизайнерам"
          />
        }
        title="Если нет времени собирать фотокнигу самостоятельно"
        text="Доверьте оформление семейных воспоминаний нашим дизайнерам"
        link="/design-photobook/"
        buttonText="Узнать подробнее"
      />

      <RecommendationsGroupped />

      <ReviewsList />

      <PromoBlock
        image={
          <StaticImage
            src="../images/support.jpg"
            layout="constrained"
            width={647}
            alt="Наша команда поддержки всегда рядом!"
          />
        }
        title="Наша команда поддержки всегда рядом!"
        text="Если есть вопросы по продукту, заказу или возникла любая другая ситуация — напишите нам. Мы на связи ежедневно с 10 до 22"
        link="/contacts/"
        buttonText="Связаться"
      />

      <AboutProject />
    </Layout>
  );
}

export default IndexPage;
