import React from 'react';
import { useRecommendations } from '@hooks/useRecommendations';
import { Recommendations } from './Recommendations';
import Filters from './Filters';
import styles from './RecommendationsGroupped.module.scss';

export function RecommendationsGroupped() {
  // TODO: типизировать рекомендации
  const items = useRecommendations();
  const [groupId, setGroup] = React.useState(items[0].id);

  const currentGroup = items.find((g: any) => g.id === groupId);

  const handleSetFilter = React.useCallback((id: any) => setGroup(id), [setGroup]);

  return (
    <div className={styles.recommendations}>
      <div className={styles.wrapper}>
        <h2 className={styles.header}>Для каждой истории — своя фотокнига</h2>
      </div>
      <div className={styles.menu}>
        <Filters filters={items} activeId={groupId} handler={handleSetFilter} />
      </div>
      <div className={styles.itemsWrapper}>
        <Recommendations items={currentGroup.products} />
      </div>
    </div>
  );
}
