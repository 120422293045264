import React, { type PropsWithChildren, type ReactNode } from 'react';

import { Button } from '@periodica/ui-kit';
import { Link } from 'gatsby';

import styles from './PromoBlock.module.scss';

function ExternalLink({ to, children, ...props }: PropsWithChildren<{ to: string }>) {
  if (to.startsWith('http')) {
    return (
      <a href={to} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
}

interface PromoBlock {
  image: ReactNode | undefined;
  title: string;
  text: string;
  link: string;
  buttonText?: string;
}

export function PromoBlock({
  image: promoImage,
  title: promoTitle,
  text: promoText,
  link: promoLink,
  buttonText = 'Подробнее',
}: PromoBlock) {
  return (
    <div className={styles.aboutContainer}>
      <div className={styles.image}>{promoImage}</div>
      <div className={styles.aboutTextContainer}>
        <div className={styles.aboutText}>
          <h2>{promoTitle}</h2>
          <p>{promoText}</p>
          <Button size="xLarge" asChild>
            <ExternalLink to={promoLink}>{buttonText}</ExternalLink>
          </Button>
        </div>
      </div>
    </div>
  );
}
