import React, { type ReactNode } from 'react';

import { Link } from 'gatsby';
import { Button } from '@periodica/ui-kit';

import styles from './Review.module.scss';

interface ReviewArgs {
  name: string;
  photo: ReactNode | undefined;
  bookUrl: string;
}

export function Review({ name, photo, bookUrl }: ReviewArgs) {
  return (
    <div className={styles.reviewBlock}>
      <div className={styles.reviewBlock__pic}>{photo}</div>
      <p className={styles.reviewBlock__name}>{name}</p>
      <Button size="medium" asChild>
        <Link to={bookUrl}>Заказать эту книгу</Link>
      </Button>
    </div>
  );
}
